'use strict';

angular.module('windmanagerApp')
  .filter('bladePosition', function () {
    return function (blade) {
      if(!blade) {
        return '';
      }
      if(blade.position && (blade.position == 'A' || blade.position == '1')) {
        return 'A(1)';
      }
      if(blade.position && (blade.position == 'B' || blade.position == '2')) {
        return 'B(2)';
      }
      if(blade.position && (blade.position == 'C' || blade.position == '3')) {
        return 'C(3)';
      }
      return '';
    };
  });
